// hover and focus code
.hover\:primary-color:enabled:hover{
    background: $primary-color!important;
    border-color: $primary-color;
    color: $white;
}
.hover\:primary-color:hover{
    background: $primary-color;
    border-color: $primary-color;
    color: $white;
}

.hover\:secondry-color:enabled:hover{
    background-color: $secondry-color;
    border-color: transparent;
}

.hover\:borders-primary:hover{
    @extend .borders-primary;
}
.hover\:txt-primary:enabled:hover{
    @extend .txt-primary;
}
.hover\:txt-white:enabled:hover{
    @extend .txt-white;
}
.hover\:txt-light-black:enabled:hover{
    @extend .txt-light-black;
}
.hover\:txt-light-black:hover{
    @extend .txt-light-black
}
.hover\:background-transparent:enabled:hover{
    background-color: transparent;
}
.hover\:txt-anchor:hover{
    color: $anchor;
}
.hover\:txt-light-gray:enabled:hover{
    @extend .txt-light-gray;
}


.focus\:borders-primary:focus{
    border-color: $primary-color;
}

.focus\:outline-none:focus{
    box-shadow: none;
}

.focus\:box-shadow:focus{
    box-shadow: 0px 4px 15px rgba($color: $black, $alpha: 0.3);
}

.focus\:border-1:focus{
    border: 2px solid $primary-color!important;
}

.focus\:txt-red:focus{
    color: $red;
}

.active\:active-none:enabled:active{
    background: transparent;
}
