// Font Size
.font-10{
    font-size: 10px;
    line-height: 12px;
}
.font-12{
    font-size: 12px;
    line-height: 14px;
}
.font-14{
    font-size: 14.9091px;
    line-height: 17px;
}
.font-15{
    font-size: 15px;
    line-height: 18px;
}
.font-16{
    font-size: 16px;
    line-height: 19px;
}
.font-17{
    font-size: 17px;
    line-height: 20px;
}
.font-18{
    font-size: 18.7168px;
    line-height: 22px;
}

.font-19{
    font-size: 19.2371px;
    line-height: 23px;
}
.font-20{
    font-size: 20px;
    line-height: 23px;
}
.font-24{
    font-size: 24.0039px;
    line-height: 28px;
}

.font-30{
    font-size: 30px;
    line-height: 35px;
}

.font-34{
    font-size: 34px;
    line-height: 40px;
}
.letter-spacing{
    letter-spacing: -0.18px;
}

@media (max-width: 768px){
    .font-14{
        font-size: 12px;
        line-height: 15px;
    }
    .font-15{
        font-size: 13px;
        line-height: 17px;
    }
    .font-16{
        font-size: 14px;
        line-height: 18px;
    }
    .font-17{
        font-size: 15px;
        line-height: 19px;
    }
    .font-18{
        font-size: 16px;
        line-height: 20px;
    }
    
    .font-19{
        font-size: 17px;
        line-height: 21px;
    }
    .font-20{
        font-size: 18px;
        line-height: 22px;
    }
    .font-24{
        font-size: 21px;
        line-height: 25px;
    }
    
    .font-30{
        font-size: 25px;
        line-height: 29px;
    }
    
    .font-34{
        font-size: 29px;
        line-height: 33px;
    }
}

@media (max-width: 479px){
    .font-10{
        font-size: 10px;
        line-height: 12px;
    }
    .font-12{
        font-size: 10px;
        line-height: 12px;
    }
    .font-14{
        font-size: 11px;
        line-height: 14px;
    }
    .font-15{
        font-size: 12px;
        line-height: 16px;
    }
    .font-16{
        font-size: 13px;
        line-height: 18px;
    }
    .font-17{
        font-size: 14px;
        line-height: 18px;
    }
    .font-18{
        font-size: 15px;
        line-height: 19px;
    }
    
    .font-19{
        font-size: 16px;
        line-height: 20px;
    }
    .font-20{
        font-size: 17px;
        line-height: 21px;
    }
    .font-24{
        font-size: 20px;
        line-height: 24px;
    }
    
    .font-30{
        font-size: 24px;
        line-height: 28px;
    }
    
    .font-34{
        font-size: 28px;
        line-height: 32px;
    }
}
