$background-color_1: #2e4361;

.errorWrapper {
    color: #dc3545;
    font-size: 0.85rem;
}
.thumbPointer {cursor: pointer;}

.apexcharts-canvas{
    .apexcharts-inner{
        .apexcharts-pie > g {
            transform: translate(0, 30px) !important;
        }
    }
}


.heatMapWrap {
	.p-carousel {
		.p-carousel-indicators {
			.p-carousel-indicator{
                button {
                    border-radius: 10px;
                }
                &.p-highlight {
                    button {
                        background-color: $background-color_1;
    
                        &:focus{
                            box-shadow: none;
                        }
                    }
                }
            }
            
		}
	}
}



.alert_noDataFound{
    .p-message.p-message-info {
        background: #d1e4fe;
        border-left: 6px solid #7391bc;
        color: $background-color_1;

        .p-message-wrapper{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
     .p-messages-btn{
        button{
            background: transparent;
            border: 0;
            color: $background-color_1;
            border-radius: 50px;
            &:hover{
                box-shadow: none;
            }
            &:active{
                box-shadow: 0px 216.626px 87.2738px rgba(0, 0, 0, 0.01), 
                0px 121.56px 73.2477px rgba(0, 0, 0, 0.05), 
                0px 54.5461px 54.5461px rgba(0, 0, 0, 0.09), 
                0px 14.0261px 29.6108px rgba(0, 0, 0, 0.1), 
                0px 0px 0px rgba(0, 0, 0, 0.1);
            }
          }
        }
    }
}


.tagWraper{
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .pi-filter-fill{
        color: $primary-color;
    }

    .p-tag{
        background-color: $body;
        color: $primary-color;
        font-size: 0.85rem;
        padding: 0.25rem 0.5rem;
    }
}

.firstTag{
    .p-tag{
        text-transform: capitalize;
        font-weight: 900;
    }
}

.p-dropdown.CustomDropdown{
    background-color: $primary-color !important;
    border-radius: 10px;
    font-size: 17px;
    text-align: left;
    
    .p-dropdown-label{
        min-width: 100px;
        color: $white;
        padding: 15px;
        text-align: left;
    }
    .p-dropdown-trigger{
        color: $white;
    }
}

.productComparsionTable.p-datatable{
        .p-datatable-table {
            .p-datatable-thead{
                background-color: #fff !important;
                border-bottom: 1px solid $gray-8;
            }
            .p-datatable-thead>tr>th,
            .p-datatable-tbody>tr>td{
                text-align: center;
                justify-content: center;
            }
            .p-datatable-thead>tr>th{
                background-color: transparent  !important;
                font-size: 18px;
                font-weight: 600;
                color:$primary-color;
            }
            .p-datatable-tbody>tr>td{
                background-color: transparent  !important;
                font-size: 16px;
                font-weight: 400;
                color:$primary-color;
                border-bottom: 1px solid $gray-8;
                border-right: 1px solid $gray-8;
                border-radius: 0;
                
                &:nth-child(1){
                    border-left: 1px solid $gray-8;
                }
            }
        }
}
.p-datatable .p-datatable-tbody > tr.p-highlight, 
.p-datatable .p-datatable-tbody > tr:hover{
    box-shadow: none;
}

.heatMapWrap {
    @include breakpoint(md) {
        padding: 0 !important;
    }

    .p-carousel-prev, .p-carousel-next{
        @include breakpoint(md) {
            width:10px !important;
            height:10px !important;
        }
        
        .pi{
            @include breakpoint(md) {
                font-size: 13px;
            }
        }
    }
    .p-carousel .p-carousel-indicators {
        padding: 0;
        margin-top: 10px;

        .p-carousel-indicator button{
            width: 20px;
            height: 5px;
        }
    }
    
}

