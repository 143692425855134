// Border color

.borders-primary{
    border-color: $primary-color;
}
.border-transparent{
    border-color: transparent;
}
.border-silver-sand{
    border-color: $silver-sand;
}
.border-gray-48{
    border-color: $gray-48;
}
.border-aquamarine-medium{
    border-color: $aquamarine-medium;
}
.border-red-crayola{
    border-color: $red-crayola;
}
.border-islamic-green{
    border-color: $islamic-green;
}

.border-yellow-orange{
    border-color: $yellow-orange;
}

.border-salmon-pink{
    border-color: $salmon-pink;
}

.border-top-solid{
    border-top-style: solid;
}

.border-left-solid{
    border-left-style: solid;
}
.border-right-solid{
    border-right-style: solid;
}
.border-bottom-solid{
    border-bottom-style: solid;
}
