@mixin border-radius($radius) {
	border-radius: $radius;
	-webkit-border-radius: $radius;
	-moz-border-radius: $radius;
}

// media query for different screens max and min

$media: (
	xs: 479px,
    sm: 640px,
    md: 768px,
    lg: 992px,
    xl: 1280px,
    2xl: 1536px,
    3xl: 1920px,
);

@mixin breakpoint($breakpoint) {
    @each $breakpoints, $size in $media {
        @if $breakpoint==$breakpoints {
            @media screen and (max-width: $size) {
                @content;
            }
        }
    }
}

@mixin device($breakpoint) {
    @each $breakpoints, $size in $media {
        @if $breakpoint==$breakpoints {
            @media screen and (min-width: $size) {
                @content;
            }
        }
    }
}


@mixin media-width-height($min-width, $max-width, $min-height, $max-height) {
  @media screen and (min-width: $min-width) and (max-width: $max-width) and (min-height: $min-height) and (max-height: $max-height) {
    @content;
  }
}


// Scrollbar
@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white,  50%)) {
	// For Google Chrome
	&::-webkit-scrollbar {
	  width:  $size;
	  height: $size;
	}
  
	&::-webkit-scrollbar-thumb {
	  background: $foreground-color;
	}
  
	&::-webkit-scrollbar-track {
	  background: $background-color;
	}
  
	// For Internet Explorer
	& {
	  scrollbar-face-color: $foreground-color;
	  scrollbar-track-color: $background-color;
	}
  }

//   Box shadow
@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
	@if $inset {
	  -webkit-box-shadow:inset $top $left $blur $color;
	  -moz-box-shadow:inset $top $left $blur $color;
	  box-shadow:inset $top $left $blur $color;
	} @else {
	  -webkit-box-shadow: $top $left $blur $color;
	  -moz-box-shadow: $top $left $blur $color;
	  box-shadow: $top $left $blur $color;
	}
  }
