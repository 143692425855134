$body: #F6F7FB;
$primary-color: #2E4361;
$secondry-color: #EAE2F3;
$white: #ffffff;
$light-black: #212426;
$anchor: #f9a535;
$light-gray: #9F9F9F;
$red: #F20000;
$placeholder: #7A7480;
$off-white: #F3F3F3;
$dark-green: #306E59;
$black: #000000;
$silver-sand: #C6C6C6;
$gray-48: #7A7A7A;
$gray-25: #ededed;
$gray-15: #c8c8c8;
$gray-8: #cfcfcf;
$aquamarine-medium: #63D1B4;
$bright-gray: #EAEAEA;
$salmon-pink: #FFA396;
$red-crayola: #ED2354;
$islamic-green:#00AE11;
$yellow-orange: #FF9404;
$whiteBox-outline: #E0E5EC;
$vLightBlue: #D9E8FF;
$vLightGrayTxt: #A8A8A8;
$vLightBlueBdr: #ADC7ED;
$grayBdr: #dddddd;
$invalidColor: #ff4560;
