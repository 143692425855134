// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$frontend-primary: mat.define-palette(mat.$indigo-palette);
$frontend-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$frontend-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$frontend-theme: mat.define-light-theme((color: (primary: $frontend-primary,
                accent: $frontend-accent,
                warn: $frontend-warn,
            )));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($frontend-theme);

/* You can add global styles to this file, and also import other style files */
@import './assets/variable';
@import './assets/font';
@import './assets/mixin';

// Border type

.radius-10 {
    @include border-radius(10px)
}

.border-2_5 {
    border-width: 2.5px;
}

.border-4 {
    border-width: 4px;
}

.box-sizing {
    box-sizing: border-box;
}

// Padding
.pad-l-40 {
    padding-left: 40px !important;
}

.pad-r-40 {
    padding-right: 40px;
}

.left-1 {
    left: 1.2rem;
}

.right-1 {
    right: 1.2rem;
}

.translate-y {
    transform: translateY(-50%);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    border: 1px solid transparent;
    -webkit-text-fill-color: $primary-color;
    -webkit-box-shadow: none;
    box-shadow: none;
    transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill::first-line {
    @extend .font-18;
    font-family: 'Roboto', sans-serif;
}


// signup flow common css
.input-email {
    .ng-otp-input-wrapper {
        text-align: center;

        .otp-input {
            border: 0;
            border-width: 0 0 2px 0;
            border-style: solid;
            border-color: $light-gray;
            background-color: transparent;
            @include border-radius(0px);
            margin-right: 15px !important;
            @extend .font-30;
            height: 70px;
            color: $light-gray;

            &:focus {
                outline: none;
            }
        }
    }
}

.set-password {
    .p-input-icon-left {
        &>i {
            margin-top: 0;
            transform: translateY(-50%);
        }

        &>i:first-of-type {
            padding-left: 1rem;
            left: 1.2rem;
        }

        &>.p-inputtext {
            padding-left: 3rem;
        }
    }

}

.btn-create {
    .p-button-label {
        flex: none;
    }
}

.p-datatable {
    .p-datatable-header {
        background: transparent;
        border: 0;
        border-bottom: 1px solid $light-gray;
    }

    .p-datatable-thead {
        &>tr {
            &>th {
                @extend .p-datatable-header, .font-20, .font-raleway, .txt-gray-48;
                border-bottom: 0;
                padding: 0.4rem 1rem;

                @include breakpoint(md) {
                    font-size: 15px;
                    line-height: 18px;
                }

                @include breakpoint(xs) {
                    font-size: 12px;
                    line-height: 14px
                }
            }

            &.cohort-head {
                th {
                    border-bottom: 1px solid $gray-48;
                }
            }
        }
    }

    .p-datatable-tbody {
        &>tr {
            @extend .radius-10;

            &>td {
                @extend .font-17, .txt-gray-48;
                background-color: $body;
                border-top: 2px solid transparent;
                border-bottom: 2px solid transparent;
                padding: 0.4rem 1rem;

                &:first-child {
                    border-top-left-radius: 10px;
                    border-bottom-left-radius: 10px;
                    border-left: 2px solid transparent;
                    @extend .txt-primary;
                }

                &:last-child {
                    border-top-right-radius: 10px;
                    border-bottom-right-radius: 10px;
                    border-right: 2px solid transparent;
                }

                @include breakpoint(md) {
                    font-size: 15px;
                    line-height: 18px;
                }

                @include breakpoint(xs) {
                    font-size: 12px;
                    line-height: 14px
                }
            }

            &.p-highlight {
                background: transparent;
                box-shadow: 0px 0px 15px rgba($color: $black, $alpha: 0.18);

                td {
                    background: $body;
                    border-top: 2px solid $primary-color;
                    border-bottom: 2px solid $primary-color;

                    &:first-child {
                        border-left: 2px solid $primary-color;
                    }

                    &:last-child {
                        border-right: 2px solid $primary-color;
                        ;
                    }
                }
            }

            &:focus {
                outline: none;
                outline-offset: 0;
            }

            &:hover {
                .view-result {
                    background-color: $primary-color;
                    color: $white;
                }

                @extend .p-highlight;
            }

            &.cohort-tr {

                &:hover {
                    box-shadow: none;

                    td {
                        background-color: transparent;
                        border: 0;
                    }
                }
            }
        }
    }

    table {
        border-collapse: separate !important;
        border-spacing: 0 20px;
        padding: 0 5px;
    }
}

.cohort-table {
    table {
        border-collapse: collapse !important;
        border-spacing: 0;
        padding: 5px;
    }

    .p-datatable {
        .p-datatable-thead {
            &>tr {
                &>th {
                    @extend .font-12, .txt-gray-48;
                    border: 1px solid $silver-sand;
                    border-right: 0;
                    padding: 0.7rem;

                    &:last-child {
                        border-right: 1px solid $silver-sand;
                    }
                }
            }
        }

        .p-datatable-tbody {
            &>tr {
                &>td {
                    border: 1px solid $silver-sand;
                    border-top: 0;
                    border-right: 0;
                    padding: 0.7rem;
                    border-radius: 0 !important;
                    padding: 0;

                    &:last-child {
                        border-right: 1px solid $silver-sand;
                    }

                    input {
                        @extend .font-12, .txt-gray-48;
                        height: 34px;
                        background-color: transparent;
                        padding: 0.2rem;
                        &.ng-invalid {
                            -webkit-box-shadow: inset 0 0 0.5px 1px #DA6363;
                            box-shadow: inset 0 0 0.5px 1px #DA6363;
                        }
                    }
                }

                &:hover {
                    td {
                        border: 1px solid $silver-sand;
                        border-top: 0;
                        border-right: 0;

                        &:last-child {
                            border-right: 1px solid $silver-sand;
                        }
                    }
                }
            }
        }
    }
}

canvas {
    background-color: $white;
}

.circle-graph {
    width: 120px;
    height: 120px;
    @extend .font-raleway;

    &.circle-graph-inner {
        width: 80px;
        height: 80px;
    }
}

.mcq-accordion {
    height: 395px;

    .p-accordion {
        .p-accordion-header {
            &:not(.p-disabled) {
                &.p-highlight {
                    .p-accordion-header-link {
                        background-color: $white;
                        border: 0;
                        @extend .font-raleway, .font-17, .txt-primary;
                        font-weight: 700;

                        &:focus {
                            box-shadow: none;
                        }
                    }
                }

                .p-accordion-header-link {
                    &:focus {
                        box-shadow: none;
                    }
                }
            }

            .p-accordion-header-link {
                background-color: $white;
                border: 0;
                @extend .font-raleway, .font-17, .txt-primary;
                font-weight: 700;
                padding: 0.5rem 1rem 0 1rem;

                .p-accordion-toggle-icon {
                    position: absolute;
                    right: 0;
                }

                &:hover {
                    background-color: transparent;
                }
            }

            &:not(.p-highlight) {
                &:not(.p-disabled) {
                    &:hover {
                        .p-accordion-header-link {
                            background-color: transparent;
                            border-color: transparent;
                            @extend .txt-primary;
                        }
                    }
                }
            }
        }

        .p-accordion-content {
            border: 0;
            @extend .txt-primary;
        }
    }

}

.p-tabview-nav {
    li {
        .p-tabview-nav-link {
            background-color: transparent;
            @extend .font-raleway, .font-15, .txt-primary;
        }

        &.p-highlight {
            .p-tabview-nav-link {
                border-color: $primary-color;
            }
        }
    }
}

.subject-list {
    .p-tabview {

        .p-tabview-nav,
        .p-tabview-panels {
            background-color: transparent;
        }

        .p-tabview-panels {
            padding: 0.2rem 0 0;
        }

        .p-tabview-nav {
            li {
                .p-tabview-nav-link {
                    background-color: transparent;
                    @extend .font-raleway, .font-15, .txt-primary;
                }

                &.p-highlight {
                    .p-tabview-nav-link {
                        border-color: $primary-color;
                    }
                }
            }
        }
    }
    .mat-video-range{
        .mat-slider-horizontal {
            height: 0px;
            padding: 0;

            .mat-slider-thumb-label {
                transform: none;
                right: -17px;
                top: -50px;

                .mat-slider-thumb-label-text {
                    opacity: 1;
                    transform: none;
                    display: none;
                    @extend .font-raleway, .font-14, .txt-primary;
                }
            }

            .mat-slider-track-wrapper {
                height: 0
            }
        }

        .mat-slider.mat-accent {
            .mat-slider-thumb-label {
                background-color: transparent
            }
        }

        .mat-slider-thumb {
            width: 15px;
            height: 15px;
            bottom: 5px;
            right: -10px;
        }

        .mat-slider.mat-slider-min-value.mat-slider-thumb-label-showing .mat-slider-thumb {
            background-color: var(--border-color-var)
        }
    }
    .expression-track{
        // .eye-track__right{
        //     .apexcharts-graphical {
        //         transform: translate(0, 0) !important;
        //     }
        // }
        #topHeadmapChart {
            height: 55px;
            margin-right: 80px;
            width: calc(100% - 80px);
            overflow: hidden;

            // .apexcharts-graphical {
            //     transform: translate(0, 0) !important;
            // }
        }
        .emotionChart{
            .apexcharts-legend{
                left: inherit!important;
                display: block!important;
                text-align: left!important;
                width: 100px!important;
                right: 0!important;
                .apexcharts-legend-series{
                    margin: 6px 0!important;
                    .apexcharts-legend-marker{flex: 0 0 20px!important;border-radius: 0!important;height: 8px!important;}
                    .apexcharts-legend-text{padding-left: 22px!important; color: #2E4361!important;}
                }
            }
        }
    }
}

.time-slot {
    .p-calendar {

        .p-inputtext {
            @extend .font-raleway, .font-17, .txt-primary;
            font-weight: 700;
            height: 34px;
        }

        .p-button {
            &.p-button-icon-only {
                background-color: $primary-color;
                border-color: $primary-color;
            }
        }
    }
}

.p-multiselect {
    width: 100%;
}

.p-datepicker-trigger {
    height: 34px;
}

.p-calendar {
    .p-inputtext {
        width: 100px !important;
    }
}

.sensor-dropdown {
    .p-dropdown {
        width: 100%;
        @extend .font-raleway, .font-17, .txt-primary;
        font-weight: 700;
        @include border-radius(5px);

        &.p-dropdown-open {
            @extend .primary-color;

            .p-dropdown-label {
                &.p-placeholder {
                    @extend .txt-white;
                }
            }
        }

        .p-dropdown-label {
            &.p-placeholder {
                @extend .txt-primary, .font-18;
            }
        }

        .p-dropdown-panel {
            background-color: transparent;
            @include box-shadow(0, 0, 10px, rgba($color: $black, $alpha: 0.1));
            margin-top: 10px;

            .p-dropdown-items {
                padding: 0;

                .p-element {
                    .p-dropdown-item {
                        background-color: #F4F9FF;
                        @extend .font-raleway, .font-16, .txt-primary;
                        font-weight: bold;
                        padding: 1rem 0.7rem;
                    }

                    &:last-child {
                        .p-dropdown-item {
                            background-color: #FFFFFF;
                            font-weight: normal;
                        }
                    }
                }

            }
        }
    }
}

@media screen and (min-width: 992px) {
    .lg\:bottom-1 {
        bottom: 1.2rem;
    }

    .lg\:left-1 {
        left: 1.2rem;
    }
}

.spinner-loader {
    position: fixed;
    left: 0%;
    top: 50%;
    width: 100%;
    height: 100%;
    z-index: 9999;
}

#loader {
    margin: 0 auto;
}

:host ::ng-deep .mat-spinner circle {
    stroke: #5ab6f3;
    stroke-width: 4% !important;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    .p-button:not(.bLogIn):enabled:hover,
    .expSubjList .p-button.p-button-text:enabled:hover,
    .btnsShadow .p-button.p-button-text:enabled:hover,
    .uploadBar .p-fileupload-buttonbar .p-button.p-fileupload-choose:hover,
    .minHCtr .p-button.p-button-text.selectBtn:hover {
        color: #ffffff;
        background: $primary-color;
        border-color: $primary-color;       
    }
    a.wShadow:hover,
    button.wShadow:hover,
    a.wShadow:focus,
    button.wShadow:focus,
    .p-button:not(.bLogIn):enabled:hover,
    .expSubjList .p-button.p-button-text:enabled:hover,
    .btnsShadow .p-button.p-button-text:enabled:hover,
    .uploadBar .p-fileupload-buttonbar .p-button.p-fileupload-choose:hover,
    .minHCtr .p-button.p-button-text.selectBtn:hover,
    .p-button:not(.bLogIn):enabled:focus,
    .expSubjList .p-button.p-button-text:enabled:focus,
    .btnsShadow .p-button.p-button-text:enabled:focus,
    .uploadBar .p-fileupload-buttonbar .p-button.p-fileupload-choose:focus,
    .minHCtr .p-button.p-button-text.selectBtn:focus,
    .selectBtn:hover,
    .selectBtn:focus {
        -webkit-transition-duration: 0s !important;
        transition-duration: 0s !important;        
    }
    .p-button:not(.bLogIn):enabled:focus,
    .p-button.p-button-text.selectBtn:focus {
        -webkit-box-shadow: 0 0 0 transparent;
        box-shadow: 0 0 0 transparent;
    }
    a.wShadow:hover,
    button.wShadow:hover,
    a.wShadow:focus:hover,
    button.wShadow:focus:hover,
    .p-button:not(.bLogIn):enabled:hover,
    .expSubjList .p-button.p-button-text:enabled:hover,
    .btnsShadow .p-button.p-button-text:enabled:hover,
    .uploadBar .p-fileupload-buttonbar .p-button.p-fileupload-choose:hover,
    .minHCtr .p-button.p-button-text.selectBtn:hover,
    .p-button:not(.bLogIn):enabled:focus:hover,
    .expSubjList .p-button.p-button-text:enabled:focus:hover,
    .btnsShadow .p-button.p-button-text:enabled:focus:hover,
    .uploadBar .p-fileupload-buttonbar .p-button.p-fileupload-choose:focus:hover {        
        // -webkit-box-shadow: 0px 216.626px 87.2738px rgba(0, 0, 0, 0.01), 0px 121.56px 73.2477px rgba(0, 0, 0, 0.05), 0px 54.5461px 54.5461px rgba(0, 0, 0, 0.09), 0px 14.0261px 29.6108px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
        // box-shadow: 0px 216.626px 87.2738px rgba(0, 0, 0, 0.01), 0px 121.56px 73.2477px rgba(0, 0, 0, 0.05), 0px 54.5461px 54.5461px rgba(0, 0, 0, 0.09), 0px 14.0261px 29.6108px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: 0px 5px 20px rgba(0,0,0,0.3);
        box-shadow: 0px 5px 20px rgba(0,0,0,0.3);
    }
    .p-button-text.selectBtn:focus:hover {        
        // -webkit-box-shadow: 0px 216.626px 87.2738px rgba(0, 0, 0, 0.01), 0px 121.56px 73.2477px rgba(0, 0, 0, 0.05), 0px 54.5461px 54.5461px rgba(0, 0, 0, 0.09), 0px 14.0261px 29.6108px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
        // box-shadow: 0px 216.626px 87.2738px rgba(0, 0, 0, 0.01), 0px 121.56px 73.2477px rgba(0, 0, 0, 0.05), 0px 54.5461px 54.5461px rgba(0, 0, 0, 0.09), 0px 14.0261px 29.6108px rgba(0, 0, 0, 0.1), 0px 0px 0px rgba(0, 0, 0, 0.1) !important;
        -webkit-box-shadow: 0px 5px 20px rgba(0,0,0,0.3);
        box-shadow: 0px 5px 20px rgba(0,0,0,0.3);
    }
    .p-button.btn-create:hover,
    .p-button.p-button-text.txtOnlyAddBtn:hover {
        color: inherit !important;
        opacity: 0.8;
        background-color: transparent !important;
        border-color: transparent !important;        
    }

    .p-button.btn-create:hover,
    .p-button.p-button-text.txtOnlyAddBtn:hover,
    .nShadow:hover,
    .p-button.btn-create:focus,
    .p-button.p-button-text.txtOnlyAddBtn:focus,
    .nShadow:focus:hover,
    .p-button.btn-create:focus:hover,
    .p-button.p-button-text.txtOnlyAddBtn:focus:hover,
    .nShadow:focus {
        -webkit-box-shadow: 0 0 0 transparent !important;
        box-shadow: 0 0 0 transparent !important;
    }
    .uploadBar .p-fileupload-buttonbar .p-button.p-fileupload-choose {
        @include border-radius(10px);
    }
    .expSubjList .p-button:enabled,
    .btnsShadow .p-button:enabled {
        position: relative;
        z-index: 2;
    }
    

    .addProRow input.w-full.p-inputtext.ng-invalid {
        color: $invalidColor;
        box-shadow: 0 0 0 0.2rem rgba($invalidColor, 0.4);
    }
    td input.invalid-input {
        color: $invalidColor !important;
        border-color: $invalidColor !important;
    }
}

@media print {
    .block.hide2PDF {
        display: none !important;
    }

    body .highcharts-credits {
        display: none !important;
    }
}

/* 09-06-2022 (Cohort) */
.cohortWrapper {
    background-color: #FAFBFF;
    @include border-radius(16px);

    .cohort-table {

        .p-datatable-thead>tr>th,
        .p-datatable-tbody>tr>td,
        .p-inputtext {
            border-color: #464646;
            color: #464646 !important;

            &.ng-invalid {
                border-color: #f44336;
            }
        }

        .p-element.ng-invalid {
            .p-inputtext {
                border-color: #f44336;
            }
        }

        .p-button.p-datepicker-trigger {
            background-color: $primary-color;
            border: none;
        }

        .txt-gray-48 {
            color: #464646 !important;
        }

        input[type='radio'] {
            accent-color: $primary-color;
        }
    }
}

.uploadBar {
    position: relative;

    .p-fileupload {
        &-content {
            display: none;
        }

        &-buttonbar {
            background-color: transparent;
            padding: 1em 0;
            border: none;
            text-align: right;

            .p-fileupload-choose~.p-element {
                display: none;
            }

            .pi.pi-plus.p-button-icon::before {
                content: "\e934";
            }

            .p-button.p-fileupload-choose {
                background-color: $primary-color;
                border: solid 2px $primary-color;
                color: $white;
                @include border-radius(4px);

                .p-button-label {
                    -webkit-transform: translateY(-1px);
                    transform: translateY(-1px);
                }
            }
        }
    }

    .p-button.p-button-text.selectBtn {
        position: absolute;
        right: 164px;
        top: 16px;
        @include border-radius(10px);

        &:hover {
            background-color: #2E4361 !important;
            color: #ffffff !important;
            // -webkit-box-shadow: 0 0 0 transparent;
            // box-shadow: 0 0 0 transparent;
        }
    }
}

body .apexcharts-canvas svg {
    overflow: visible;
}

h5.multiLineTitle {
    font-size: 17px;
    font-weight: bold;
    line-height: 1.3;
    font-family: 'Raleway', sans-serif;
    color: #2e4361;
    white-space: normal;
    word-wrap: break-word;
    margin: 0 0 10px;
}

.p-inputtext.pad-l-40 {
    padding-left: 54px !important;
}

.subject-list.box-shadow.bg-white,
.dashboard__content.p-3 .w-full.box-shadow.bg-white.radius-10 {
	min-height: calc(100vh - 80px);
    &.nonMinHei8 {
        min-height: calc(100vh - 468px);
    }
    .w-full.box-shadow.bg-white.radius-10 {
        min-height: auto;
    }
}

/* ======= 07-26-2023 ========= */

.p-datatable-tbody {
    td {
        input {
            background-color: #ffffff;
            border: solid 1px #C5C5C5;
            @include border-radius(8px);
            background-image: none;
            outline: none;
            padding: 8px 14px;
            font-size: 16px;
            color: #2E4361;
        }
    }
}

div.naviGateBar {
    // background-color: #E5ECF6;
    @include border-radius(10px);
    // padding: 1em;
    margin-bottom: 0.85em;
    font-weight: 500;
    color: #2E4361;
    font-size: 1.2em;
    span {
        cursor: pointer;
        display: inline-block;
        vertical-align: middle;

        &:hover {
            opacity: 0.85;
        }
    }
    i {
        font-size: 1.5em;
        font-style: normal;
        display: inline-block;
        margin: -3px 5px 0;
        vertical-align: middle;
        line-height: 1;
    }
}

button.editBtn,
button.saveBtn {
    @include border-radius(10px);
    cursor: pointer;
    white-space: nowrap;
    position: relative;
    line-height: 37px;
    height: 37px;
    vertical-align: middle;
    padding: 0 1rem 0 40px !important;
    margin: 0 8px;
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);

    &.wResult {
        height: 39px;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    &.noTAdj {
        -webkit-transform: translateY(0);
        transform: translateY(0); 
    }

    &::before {
        content: '';
        width: 17px;
        height: 14px;
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        position: absolute;
        left: 1rem;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

button.editBtn {
    background: #EEF1FF;
    border: 1px solid #C5DDFF;
    color: #2E4361;

    &::before {
        background-image: url('./assets/images/icons/list-edit-gray-ico.svg');
    }

    &:hover {
        background-color: #2E4361;
        border-color: #2E4361;
        color: #ffffff;

        &::before {
            -webkit-filter: grayscale(1) brightness(100);
            filter: grayscale(1) brightness(100);
        }
    }
}

button.saveBtn {
    background-color: #2E4361;
    border: solid 1px #2E4361;
    color: #ffffff !important;
    &:disabled {
        background-color: transparent;
        border-color: #000000;
        color: #000000 !important;
        cursor: default;
        pointer-events: none;
        opacity: 0.4;
        &::before {
            -webkit-filter: grayscale(1) brightness(0);
            filter: grayscale(1) brightness(0);
        }
    }

    &::before {
        background-image: url('./assets/images/icons/list-save-check-ico.svg');
    }
}

.fav {
    display: inline-block;
    vertical-align: middle;
    width: 24px;
    height: 24px;
    position: relative;
    &.blue {
        -webkit-transform: translateY(-2px);
        transform: translateY(-2px);
        > .starIco {
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
            opacity: 1;
        }
    }
    > .starIco,
    > input {
        position: absolute;
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
    }
    > .starIco {
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url('./assets/images/icons/star-blue-ico.svg');
        -webkit-filter: grayscale(1);
        filter: grayscale(1);
        opacity: 0.6;
    }
    > input {
        opacity: 0;
        z-index: 2;
        margin: 0;
        padding: 0;
        cursor: pointer;
        &:checked + .starIco {
            -webkit-filter: grayscale(0);
            filter: grayscale(0);
            opacity: 1;
        }
    }
}

.nonBdrBtm .p-datatable .p-datatable-header {
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
}
.p-datatable .p-datatable-tbody > tr > td.noWrap {
    white-space: nowrap;
    text-align: right
}


//CUSTOM CSS
@import './assets/user';