// Background Color

.primary-color{
    background-color: $primary-color;
}
.secondry-color{
    background-color: $secondry-color;
}

.background-transparent{
    background-color: transparent;
}

.off-white{
    background-color: $off-white;
}
.bg-silver-sand{
    background-color: $silver-sand;
}

.bg-white-5{
    background-color: rgba($color: $white, $alpha: 0.5);
}

.bg-bright-gray{
    background-color: $bright-gray;
}

.body{
    background-color: $body;
}

.hover\:background-transparent:hover{
    background-color: transparent!important;
}
.hover\:background-primary:hover{
    @extend .primary-color
}
