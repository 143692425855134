// Font color

.txt-primary{
    color: $primary-color;
}
.txt-white{
    color: $white;
}

.txt-light-black{
    color: $light-black;
}

.txt-anchor{
    color: $anchor;
}

.txt-light-gray{
    color: $light-black;
}
.txt-red{
    color: $red;
}

.txt-dark-green{
    color: $dark-green;
}

.txt-silver-sand{
    color: $silver-sand;
}

.txt-gray-48{
    color: $gray-48;
}

.txt-aquamarine-medium{
    color: $aquamarine-medium;
}

.txt-red-crayola{
    color: $red-crayola;
}

.txt-islamic-green{
    color: $islamic-green;
}

.txt-yellow-orange{
    color: $yellow-orange;
}

.txt-salmon-pink{
    color: $salmon-pink;
}

.is-invalid{
    border-color: $red!important;
}

.placeholder-color{
    &::-webkit-input-placeholder{color: $placeholder;font-weight: normal;}
    &:-ms-input-placeholder{color: $placeholder;font-weight: normal;}
    &::-moz-placeholder{color: $placeholder;font-weight: normal;}
    &:-moz-placeholder{color: $placeholder;font-weight: normal;}
    &::placeholder{color: $placeholder;font-weight: normal;}
}

@media screen and (min-width: 992px){
    .lg\:txt-white{
        color: $white;
    }
}
