@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import './fonts/font-size';
@import './fonts/background-color';
@import './fonts/border-color';
@import './fonts/font-color';
@import './fonts/hover-focus';

body{font-family: 'Roboto', sans-serif;font-size: 16px;line-height: 20px;margin: 0;padding: 0;background-color: $body;}

// FOnt family
.font-raleway{
    font-family: 'Raleway', sans-serif;
}
.font-roboto{
    font-family: 'Roboto', sans-serif;
}
.red-snackbar {
    background: hsl(9, 78%, 53%);
}
.succes-snackbar {
    background: #ffff;
    color: black !important;
}
